@media (max-width:1000px){
  .main{
    display: grid;
    
  }
  .contact{
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

   
  }
 .bannerimg{
  width: 100%;
  margin-top:0px;
 } 
 .imgmain{
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 
 .title{
  font-size:1.4375rem;
  text-align: center;
  margin-bottom: .3rem;
 }
 .sub-title{

  font-size:0.9375rem;
  text-align: center;
 }
  
}
